// src/pages/tracked-visitors.tsx
import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '../components/ui/table';
import apiInstance from '../services/api';
import dayjs from 'dayjs';
import { Pagination, PaginationContent, PaginationEllipsis, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious } from '../components/ui/pagination';
import { Skeleton } from '../components/ui/skeleton';
import { useToast } from '../components/ui/use-toast';
import { Card, CardContent, CardHeader } from '../components/ui/card';
import { Input } from '../components/ui/input';
import { Button } from '../components/ui/button';
import { LucideDelete, Trash2 } from 'lucide-react';

const TrackingList: React.FC = () => {
    const { toast } = useToast()

    const [list, setList] = useState([]);

    const [offset, setOffset] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [trackUser, setTrackUser] = useState<{ name: string, key: string }>({
        name: "",
        key: ""
    });

    const fetchList = async (offset_val: number = offset) => {
        try {
            const response = await apiInstance.apiGetListTrackVisitors(10, offset_val);
            setList(response); // Adjust according to the response structure
            setTotalPages(Math.ceil(response[0]?.total_pages))
        } catch (error) {
            console.error("Failed to fetch list", error);
        }
    };

    const addTrackUser = async () => {
        try {
            if (trackUser.name.trim() !== "" || trackUser.key.trim() !== "") {
                await apiInstance.apiAddTrackUser(trackUser.name, trackUser.key);
                toast({
                    title: "Success!",
                    description: "You have added a user to track.",
                })
                fetchList();
                setErrorMessage("")
                setTrackUser({
                    name: "",
                    key: ""
                })
            }
            else {
                setErrorMessage("One of the fields is required!");
            }
        } catch (error) {
            console.error("Failed to fetch list", error);
        }
    }

    const handlePageChange = (pageNum: number) => {
        setPage(pageNum);
        setOffset((pageNum - 1) * 10);
    };

    const deleteTrackedUser = async (uuid: string) => {
        try {
            const response = await apiInstance.apiDeleteTrackVisitorTrackVisitor(uuid);
            fetchList();
            toast({
                title: "Success!",
                description: "You have deleted a tracked user.",
            })
        } catch (error) {
            console.error("Failed to fetch list", error);
        }
    }

    useEffect(() => {
        fetchList();
    }, [offset]);


    return (
        <div className='w-full block'>
            <Card className='flex flex-col items-center mx-auto flex-[1]'>
                <CardHeader className='flex flex-row justify-between items-center'>
                    <h1 className='mb-2 text-[24px] font-semibold'>Track users and get notified</h1>
                </CardHeader>
                <CardContent>
                    <div className='flex items-end gap-3 flex-wrap'>
                        <div>
                            <label>Name</label>
                            <Input className='min-w-[250px]' onChange={e => {
                                setTrackUser({ ...trackUser, name: e.target.value });
                                setErrorMessage("");
                            }} value={trackUser.name} />
                        </div>
                        <div>
                            <label>Company</label>
                            <Input className='min-w-[250px]' onChange={e => {
                                setTrackUser({ ...trackUser, key: e.target.value });
                                setErrorMessage("");
                            }} value={trackUser.key} />
                        </div>
                        <Button type='button' className='flex items-center gap-[10px] bg-[hsl(var(--primary))] text-white' onClick={() => addTrackUser()}>Add user</Button>
                    </div>
                    {
                        errorMessage !== "" &&
                        <h1 className='text-red-500 mt-2'>{errorMessage}</h1>
                    }
                </CardContent>
            </Card>
            <hr className='my-10' />
            <div className='w-full block'>
                <div className='max-w-full'>
                    {
                        list.length ?
                            <Table className='min-w-[1400px]'>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead className='whitespace-nowrap'>Name</TableHead>
                                        <TableHead className='whitespace-nowrap'>Company</TableHead>
                                        <TableHead className='whitespace-nowrap'>Created at</TableHead>
                                        <TableHead className='whitespace-nowrap'>Is deleted</TableHead>
                                        <TableHead className='whitespace-nowrap'>Action</TableHead>
                                    </TableRow>
                                </TableHeader>
                                {
                                    !list.length ?
                                        <TableCaption>A list of leads</TableCaption>
                                        :
                                        <TableBody>
                                            {list.map((item: any, i: number) => {
                                                var date = dayjs.unix(item.created_at_unix).format('ddd MMM DD YYYY HH:mm:ss');
                                                return (
                                                    <TableRow key={i}>
                                                        <TableCell>{item.key || "N/A"}</TableCell>
                                                        <TableCell>{item.name || "N/A"}</TableCell>
                                                        <TableCell>{date}</TableCell>
                                                        <TableCell>{item.is_deleted === 0 ? "No" : "Yes"}</TableCell>
                                                        <TableCell>
                                                            <Button type='button' className='flex items-center gap-[10px] bg-[hsl(var(--destructive))] text-white' onClick={() => deleteTrackedUser(item.uuid
                                                            )}>Delete <Trash2 /></Button>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                }
                            </Table>
                            :
                            <Skeleton className="w-[100%] min-h-[500px] rounded-md" />
                    }
                    {
                        list.length !== 0 &&
                        <Pagination className='mt-5'>
                            <PaginationContent>
                                <PaginationItem className='flex items-center gap-[10px] cursor-pointer' onClick={() => {
                                    if (page > 1) {
                                        handlePageChange(page - 1)
                                    }
                                }}>
                                    <PaginationPrevious />
                                </PaginationItem>
                                <PaginationItem onClick={() => handlePageChange(1)} className='cursor-pointer'>
                                    <PaginationLink isActive={page === 1 ? true : false}>1</PaginationLink>
                                </PaginationItem>
                                {
                                    page > 3 &&
                                    <PaginationItem className='cursor-pointer'>
                                        <PaginationEllipsis />
                                    </PaginationItem>
                                }
                                {
                                    Array.from({ length: 3 }).map((_, i) => {
                                        const pageIndex = page - 1 + i;
                                        if (pageIndex > 1 && pageIndex < totalPages) {
                                            return (
                                                <PaginationItem key={pageIndex} onClick={() => handlePageChange(pageIndex)} className='cursor-pointer'>
                                                    <PaginationLink isActive={page === pageIndex ? true : false}>{pageIndex}</PaginationLink>
                                                </PaginationItem>
                                            );
                                        }
                                        return null;
                                    })
                                }
                                {
                                    page < (totalPages - 3) &&
                                    <PaginationItem className='cursor-pointer'>
                                        <PaginationEllipsis />
                                    </PaginationItem>
                                }
                                {
                                    totalPages !== 1 &&
                                    <PaginationItem onClick={() => handlePageChange(totalPages)} className='cursor-pointer'>
                                        <PaginationLink isActive={page === totalPages ? true : false}>{totalPages}</PaginationLink>
                                    </PaginationItem>
                                }
                                <PaginationItem className='flex items-center gap-[10px] cursor-pointer' onClick={() => {
                                    if (page < totalPages) {
                                        handlePageChange(page + 1)
                                    }
                                }}>
                                    <PaginationNext />
                                </PaginationItem>
                            </PaginationContent>
                        </Pagination>
                    }
                </div>
            </div >
        </div>
    );
};

export default TrackingList;