// src/pages/tracked-visitors.tsx
import React, { useEffect, useState } from 'react';
import apiInstance from '../services/api';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../components/ui/table";
import { Link, NavLink } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { CalendarIcon, Filter, RefreshCw } from 'lucide-react';
import dayjs from 'dayjs';
import { DateRange } from 'react-day-picker';
import { format } from 'date-fns';
import { Popover, PopoverContent, PopoverTrigger } from '../components/ui/popover';
import { Calendar } from '../components/ui/calendar';
import { Pagination, PaginationContent, PaginationEllipsis, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious } from '../components/ui/pagination';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../components/ui/select';
import { Skeleton } from '../components/ui/skeleton';
import { Card, CardContent, CardTitle } from '../components/ui/card';

const Companies: React.FC = () => {

    const [list, setList] = useState<any[]>([]);
    const [countries, setCountries] = useState<any[]>([]);
    const [date, setDate] = useState<DateRange | undefined>({
        from: undefined,
        to: undefined,
    })
    const [country, setCountry] = useState<string>("")

    const [offset, setOffset] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);

    const fetchList = async (offset_val: number = offset, refreshFilter: boolean) => {
        try {
            if (refreshFilter) {
                setCountry("");
                setDate({
                    from: undefined,
                    to: undefined
                });
            }
            const response = await apiInstance.getList(10, offset_val, date?.from && !refreshFilter ? dayjs(date?.from).format("YYYY-MM-d") : null, date?.to && !refreshFilter ? dayjs(date?.to).format("YYYY-MM-d") : null, country && !refreshFilter ? country : "");
            setList(response); // Adjust according to the response structure
            setTotalPages(Math.ceil(response[0]?.total_pages))
        } catch (error) {
            console.error("Failed to fetch list", error);
        }
    };

    const getCountries = async () => {
        try {
            const response = await apiInstance.apiGetListOfCountries();
            setCountries(response); // Adjust according to the response structure

        } catch (error) {
            console.error("Failed to fetch list", error);
        }
    }

    const handlePageChange = (pageNum: number) => {
        setPage(pageNum);
        setOffset((pageNum - 1) * 10);
    };

    useEffect(() => {
        fetchList(offset, false);
    }, [offset]);

    useEffect(() => {
        getCountries();
    }, [])

    return (
        <div className='w-full block'>
            <div className='flex justify-between items-center mb-10'>
                <h1 className="text-3xl text-center hidden xl:block">Companies</h1>
                <div className="grid gap-2">
                    <Popover>
                        <div className='flex flex-wrap gap-3'>
                            <Select value={country} onValueChange={(value) => {
                                setCountry(value)
                            }}>
                                <SelectTrigger className="w-[180px]">
                                    <SelectValue placeholder="Country" />
                                </SelectTrigger>
                                <SelectContent>
                                    {
                                        countries.map(({ country_code, country }, i) => {
                                            return (
                                                <SelectItem key={i} value={country_code}>{country}</SelectItem>
                                            )
                                        })
                                    }
                                </SelectContent>
                            </Select>
                            <PopoverTrigger asChild>
                                <Button
                                    id="date"
                                    variant={"outline"}
                                    className="w-[300px] justify-start text-left font-normal text-muted-foreground"
                                >
                                    <CalendarIcon className="mr-2 h-4 w-4" />
                                    {date?.from ? (
                                        date.to ? (
                                            <>
                                                {format(date.from, "LLL dd, y")} -{" "}
                                                {format(date.to, "LLL dd, y")}
                                            </>
                                        ) : (
                                            format(date.from, "LLL dd, y")
                                        )
                                    ) : (
                                        <span>Pick a from/to date</span>
                                    )}
                                </Button>
                            </PopoverTrigger>
                            <div className='flex items-center gap-3'>
                                <Button type='button' className='flex items-center border-[hsl(var(--primary))] border bg-transparent gap-[10px] hover:bg-unset' onClick={() => {
                                    fetchList(offset, true)
                                }}><RefreshCw stroke='hsl(var(--primary))' /> </Button>
                                <Button type='button' className='flex items-center gap-[10px]' onClick={() => fetchList(offset, false)}><Filter /> Filter</Button>
                            </div>
                        </div>
                        <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                                initialFocus
                                mode="range"
                                defaultMonth={date?.from}
                                selected={date}
                                onSelect={setDate}
                                numberOfMonths={2}
                            />
                        </PopoverContent>
                    </Popover>
                </div>
            </div>
            <div className='max-w-full'>
                {
                    list !== null ?
                        list.length ?
                            <Table className='min-w-[1400px]'>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead className='whitespace-nowrap'>Name</TableHead>
                                        <TableHead className='whitespace-nowrap'>Website url</TableHead>
                                        <TableHead className='whitespace-nowrap'>Facebook url</TableHead>
                                        <TableHead className='whitespace-nowrap'>Linkedin url</TableHead>
                                        <TableHead className='whitespace-nowrap'>Twitter handle</TableHead>
                                        <TableHead className='whitespace-nowrap'>Employee count</TableHead>
                                        <TableHead className='whitespace-nowrap'>First visit date</TableHead>
                                        <TableHead className='whitespace-nowrap'>Last visit date</TableHead>
                                        <TableHead className='whitespace-nowrap'>Country</TableHead>
                                        <TableHead className='whitespace-nowrap'>City</TableHead>
                                        <TableHead className='whitespace-nowrap'>Region</TableHead>
                                        <TableHead className='whitespace-nowrap'>Visits</TableHead>
                                        <TableHead className='whitespace-nowrap'></TableHead>
                                    </TableRow>
                                </TableHeader>

                                <TableBody>
                                    {list.map((item: any, i: number) => (
                                        <TableRow key={i}>
                                            <TableCell className="font-medium">
                                                <div className='flex items-center gap-2'>
                                                    <div className={`bg-[rgba(0,0,0,.4)] text-white text-center flex items-center justify-center rounded-md overflow-hidden min-w-[50px] w-[50px] h-[50px]`}>{item.logo_url ? <img className='object-contain h-full w-full' src={item.logo_url} alt='logo' /> : "N/A"}</div>
                                                    {item.name}
                                                </div>
                                            </TableCell>
                                            <TableCell>{item.website_url ? <Link target='_blank' to={item.website_url} className='text-blue-500'>{item.website_url || "N/A"}</Link> : "N/A"}</TableCell>
                                            <TableCell>{item.facebook_url ? <Link target='_blank' to={item.facebook_url} className='text-blue-500'></Link> : "N/A"}</TableCell>
                                            <TableCell>{item.linkedin_url ? <Link target='_blank' to={item.linkedin_url} className='text-blue-500'>{item.linkedin_url}</Link> : "N/A"}</TableCell>
                                            <TableCell>{item.twitter_handle}</TableCell>
                                            <TableCell>{item.employee_count}</TableCell>
                                            <TableCell className='w-[160px]'>{item.first_visit_date}</TableCell>
                                            <TableCell className='w-[160px]'>{item.last_visit_date}</TableCell>
                                            <TableCell className='min-w-[150px]'>{item.country} ({item.country_code})</TableCell>
                                            <TableCell>{item.city}</TableCell>
                                            <TableCell>{item.region}</TableCell>
                                            <TableCell>{item.visits}</TableCell>
                                            <TableCell className='sticky right-[-1px] px-4 bg-white dark:bg-slate-950'>
                                                <NavLink to={`/companies/${item.uuid}`} type='button'>
                                                    <Button type='button' className='flex items-center gap-[10px]'>View</Button>
                                                </NavLink>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            :
                            <Card className='flex-1'>
                                <CardContent className='p-0'>
                                    <CardTitle className='p-4 text-center'>No data available!</CardTitle>
                                </CardContent>
                            </Card>
                        :
                        <Skeleton className="w-[100%] min-h-[650px] rounded-md" />
                }
                {
                    list.length !== 0 &&
                    <Pagination className='mt-5'>
                        <PaginationContent>
                            <PaginationItem className='flex items-center gap-[10px] cursor-pointer' onClick={() => {
                                if (page > 1) {
                                    handlePageChange(page - 1)
                                }
                            }}>
                                <PaginationPrevious />
                            </PaginationItem>
                            <PaginationItem onClick={() => handlePageChange(1)} className='cursor-pointer'>
                                <PaginationLink isActive={page === 1 ? true : false}>1</PaginationLink>
                            </PaginationItem>
                            {
                                page > 3 &&
                                <PaginationItem className='cursor-pointer'>
                                    <PaginationEllipsis />
                                </PaginationItem>
                            }
                            {
                                Array.from({ length: 3 }).map((_, i) => {
                                    const pageIndex = page - 1 + i;
                                    if (pageIndex > 1 && pageIndex < totalPages) {
                                        return (
                                            <PaginationItem key={pageIndex} onClick={() => handlePageChange(pageIndex)} className='cursor-pointer'>
                                                <PaginationLink isActive={page === pageIndex ? true : false}>{pageIndex}</PaginationLink>
                                            </PaginationItem>
                                        );
                                    }
                                    return null;
                                })
                            }
                            {
                                page < (totalPages - 3) &&
                                <PaginationItem className='cursor-pointer'>
                                    <PaginationEllipsis />
                                </PaginationItem>
                            }
                            {
                                totalPages !== 1 &&
                                <PaginationItem onClick={() => handlePageChange(totalPages)} className='cursor-pointer'>
                                    <PaginationLink isActive={page === totalPages ? true : false}>{totalPages}</PaginationLink>
                                </PaginationItem>
                            }
                            <PaginationItem className='flex items-center gap-[10px] cursor-pointer' onClick={() => {
                                if (page < totalPages) {
                                    handlePageChange(page + 1)
                                }
                            }}>
                                <PaginationNext />
                            </PaginationItem>
                        </PaginationContent>
                    </Pagination>
                }
            </div>
        </div >
    );
};

export default Companies;
