// src/services/api.ts
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Endpoint } from '../utils/endpoints';
import { string } from 'yup';

class ApiService {
    private async callApi<T>(endpoint: { url: string; method: string }, data?: any): Promise<T> {
        try {


            const config: AxiosRequestConfig = {
                url: endpoint.url,
                method: endpoint.method,
                data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
            };

            const response: AxiosResponse<T> = await axios.request(config);

            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 401) {
                    localStorage.removeItem('token');
                    window.location.href = "/";
                }
            }
            console.log(error);

            return Promise.reject();
        }
    }

    public async login(username: string, password: string): Promise<{ data: { token: string, message: string } }> {
        return this.callApi<{ data: { token: string, message: string } }>(Endpoint.auth.login(), { username, password });
    }

    public async getList(page: number, size: number, from: string | null, to: string | null, country: string): Promise<any> {
        return this.callApi<any>(Endpoint.list.getList(page, size, from, to, country));
    }

    public async apiGetListTrackVisitors(page: number, size: number): Promise<any> {
        return this.callApi<any>(Endpoint.list.getListTrackVisitors(page, size));
    }

    public async apiGetUserProfile(): Promise<any> {
        return this.callApi<any>(Endpoint.user.getUserProfile());
    }

    public async apiCountNotifications(): Promise<any> {
        return this.callApi<any>(Endpoint.notifications.countNotifications());
    }

    public async apiGetAllNotifications(page: number, size: number): Promise<any> {
        return this.callApi<any>(Endpoint.notifications.getAllNotifications(page, size));
    }

    public async apiAddTrackUser(name: string, key: string): Promise<any> {
        return this.callApi<any>(Endpoint.user.addTrackUser(), { name, key });
    }

    public async apiGetNotificationDetails(uuid: string): Promise<any> {
        return this.callApi<any>(Endpoint.notifications.getNotificationDetails(uuid));
    }

    public async apiGetMostFrequentedVisitors(): Promise<any> {
        return this.callApi<any>(Endpoint.user.getMostFrequentedVisitors());
    }

    public async apiGetDashboardBoxes(): Promise<any> {
        return this.callApi<any>(Endpoint.user.getDashboardBoxes());
    }

    public async apiGetListOfCountries(): Promise<any> {
        return this.callApi<any>(Endpoint.list.getListOfCountries());
    }

    public async apiGetvisitorsTrackingLast30Days(): Promise<any> {
        return this.callApi<any>(Endpoint.user.getvisitorsTrackingLast30Days());
    }

    public async apiGetvisitorsTrackingLast30DaysByCountry(country: string): Promise<any> {
        return this.callApi<any>(Endpoint.user.getvisitorsTrackingLast30DaysByCountry(country));
    }

    public async apiGetMatchingVisitors(): Promise<any> {
        return this.callApi<any>(Endpoint.user.getMatchingVisitors());
    }

    public async apiDeleteTrackVisitorTrackVisitor(uuid: string): Promise<any> {
        return this.callApi<any>(Endpoint.list.deleteTrackVisitorTrackVisitor(uuid));
    }

    public async apiGetPeople(): Promise<any> {
        return this.callApi<any>(Endpoint.list.getPeople());
    }

    public async apiGetPeopleById(peopleId: number | undefined): Promise<any> {
        return this.callApi<any>(Endpoint.list.getPeopleById(peopleId));
    }

    public async apiGetVisitorById(companyId: string | undefined): Promise<any> {
        return this.callApi<any>(Endpoint.list.getVisitorById(companyId));
    }

    public async getMatchedPeopleWithTrackVisitors(): Promise<any> {
        return this.callApi<any>(Endpoint.list.apiGetMatchedPeopleWithTrackVisitors());
    }

    public async getMatchingCompaniesWithTrackingVisitors(): Promise<any> {
        return this.callApi<any>(Endpoint.list.apiGetMatchingCompaniesWithTrackingVisitors());
    }
}

const apiInstance = new ApiService();
export default apiInstance;
