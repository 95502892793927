// src/App.tsx or wherever your routes are defined
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './layouts/Layout';
import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';
import Visitors from './pages/People';
import Profile from './pages/Profile';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import { Toaster } from '../src/components/ui/toaster';
import User from './pages/User';
import Dashboard from './pages/Dashboard';
import TrackingList from './pages/TrackingList';
import Companies from './pages/Companies';
import People from './pages/People';
import Matching from './pages/Matching';
import CompanyById from './pages/CompanyById';
import NotificationUser from './pages/NotificationUser';

const App: React.FC = () => {
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={
                        <PublicRoute>
                            <Login />
                        </PublicRoute>
                    } />
                    <Route path="/dashboard" element={
                        <PrivateRoute>
                            <Layout />
                        </PrivateRoute>
                    }>
                        <Route index element={<Dashboard />} />
                    </Route>
                    <Route path="/people" element={
                        <PrivateRoute>
                            <Layout />
                        </PrivateRoute>
                    }>
                        <Route index element={<People />} />
                    </Route>
                    <Route path="/companies" element={
                        <PrivateRoute>
                            <Layout />
                        </PrivateRoute>
                    }>
                        <Route index element={<Companies />} />
                    </Route>
                    <Route path="/companies/:id" element={
                        <PrivateRoute>
                            <Layout />
                        </PrivateRoute>
                    }>
                        <Route index element={<CompanyById />} />
                    </Route>
                    <Route path="/profile" element={
                        <PrivateRoute>
                            <Layout />
                        </PrivateRoute>
                    }>
                        <Route index element={<Profile />} />
                    </Route>
                    <Route path="/tracking-list" element={
                        <PrivateRoute>
                            <Layout />
                        </PrivateRoute>
                    }>
                        <Route index element={<TrackingList />} />
                    </Route>
                    <Route path="/matching-lists" element={
                        <PrivateRoute>
                            <Layout />
                        </PrivateRoute>
                    }>
                        <Route index element={<Matching />} />
                    </Route>
                    <Route path="/user/:uuid" element={
                        <PrivateRoute>
                            <Layout />
                        </PrivateRoute>
                    }>
                        <Route index element={<User />} />
                    </Route>
                    <Route path="/person/:id" element={
                        <PrivateRoute>
                            <Layout />
                        </PrivateRoute>
                    }>
                        <Route index element={<NotificationUser />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Router>
            <Toaster />
        </>
    );
};

export default App;
