import { Building, Calendar, Circle, Facebook, Flag, Globe, Linkedin, Map, PinIcon, User2, X } from 'lucide-react';
import apiInstance from '../services/api';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../components/ui/card';
import { Button } from '../components/ui/button';
import dayjs from 'dayjs';

const CompanyById = () => {
    let { id } = useParams();

    const [company, setCompany] = useState<any>(null);

    const getCompanyById = async () => {
        try {
            if (id !== undefined) {
                const response = await apiInstance.apiGetVisitorById(id);
                setCompany(response);
                console.log(response);

            }
        } catch (error) {
            console.error("Failed to fetch list", error);
        }
    }

    useEffect(() => {
        getCompanyById();
    }, [id])

    return (
        <div>
            <h1 className='text-[36px] font-bold mb-4 flex items-center gap-3'>
                {company?.v_logo_url ? <img src={company.v_logo_url} alt='logo' className='max-w-[50px] rounded-md bg-white bg-opacity-15' /> : null}
                {company?.v_name}
            </h1>
            <div className='flex gap-3 w-full flex-col md:flex-row'>
                <Card className='flex-1'>
                    <CardHeader>
                        <PinIcon size={40} />
                        <CardDescription className='text-[16px]'>Number of visits</CardDescription>
                        <CardTitle>{company?.number_of_visits}</CardTitle>
                    </CardHeader>
                </Card>
                <Card className='flex-1'>
                    <CardHeader>
                        <Calendar size={40} />
                        <CardDescription className='text-[16px]'>First visit date</CardDescription>
                        <CardTitle>{dayjs(company?.first_visit_date).format('ddd MMM DD YYYY, HH:mm:ss')}</CardTitle>
                    </CardHeader>
                </Card>
                <Card className='flex-1'>
                    <CardHeader>
                        <Calendar size={40} />
                        <CardDescription className='text-[16px]'>Last visit date</CardDescription>
                        <CardTitle>{dayjs(company?.last_visit_date).format('ddd MMM DD YYYY, HH:mm:ss')}</CardTitle>
                    </CardHeader>
                </Card>
            </div>
            <hr className='my-10' />
            <div className='flex gap-3 mb-3 flex-col md:flex-row'>
                {
                    (company?.v_facebook_url || company?.v_twitter_handle || company?.v_linkedin_url) &&
                    <Card className='flex-1'>
                        <CardHeader>
                            <CardTitle>Social medias</CardTitle>
                        </CardHeader>
                        <CardContent className='flex gap-3'>
                            {
                                company?.v_facebook_url &&
                                <Link target='_blank' to={company?.v_facebook_url}>
                                    <Button className='flex items-center gap-3'>
                                        <Facebook />
                                        Facebook
                                    </Button>
                                </Link>
                            }
                            {
                                company?.v_twitter_handle &&
                                <Link target='_blank' to={company?.v_twitter_handle}>
                                    <Button className='flex items-center gap-3'>
                                        <X />
                                        X (Twitter)
                                    </Button>
                                </Link>
                            }
                            {
                                company?.v_linkedin_url &&
                                <Link target='_blank' to={company?.v_linkedin_url}>
                                    <Button className='flex items-center gap-3'>
                                        <Linkedin />
                                        Linkedin
                                    </Button>
                                </Link>
                            }
                        </CardContent>
                    </Card>
                }
                {
                    (company?.v_website_url) &&
                    <Card className='flex-1'>
                        <CardHeader>
                            <CardTitle>Website</CardTitle>
                        </CardHeader>
                        <CardContent className='flex gap-3'>
                            {
                                company?.v_website_url &&
                                <Link target='_blank' to={company.v_website_url}>
                                    <Button className='flex items-center gap-3'>
                                        <Globe />
                                        {company.v_website_url}
                                    </Button>
                                </Link>
                            }
                        </CardContent>
                    </Card>
                }
            </div>
            {
                (company?.v_country || company?.v_city || company?.v_region || company?.v_state_code || company?.v_country_code) &&
                <Card>
                    <CardHeader>
                        <CardTitle>Other details</CardTitle>
                    </CardHeader>
                    <CardContent className='flex gap-10'>
                        <img src='/user-details-svg.svg' className='opacity-50 w-full max-w-[400px] hidden lg:block' />
                        <div className='w-full gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                            {
                                company?.v_country &&
                                <Card className='flex-1 flex justify-center items-center'>
                                    <CardHeader>
                                        <div className='flex gap-3'>
                                            <Map size={24} />
                                            <div>
                                                <CardTitle>Country</CardTitle>
                                                <CardDescription className='text-[16px] mt-3'>{company?.v_country}</CardDescription>
                                            </div>
                                        </div>
                                    </CardHeader>
                                </Card>
                            }
                            {
                                company?.v_city &&
                                <Card className='flex-1 flex justify-center items-center'>
                                    <CardHeader>
                                        <div className='flex gap-3'>
                                            <Building size={24} />
                                            <div>
                                                <CardTitle>City</CardTitle>
                                                <CardDescription className='text-[16px] mt-3'>{company?.v_city}</CardDescription>
                                            </div>
                                        </div>
                                    </CardHeader>
                                </Card>
                            }
                            {
                                company?.v_region &&
                                <Card className='flex-1 flex justify-center items-center'>
                                    <CardHeader>
                                        <div className='flex gap-3'>
                                            <Flag size={24} />
                                            <div>
                                                <CardTitle>Region</CardTitle>
                                                <CardDescription className='text-[16px] mt-3'>{company?.v_region}</CardDescription>
                                            </div>
                                        </div>
                                    </CardHeader>
                                </Card>
                            }
                            {
                                company?.v_state_code &&
                                <Card className='flex-1 flex justify-center items-center'>
                                    <CardHeader>
                                        <div className='flex gap-3'>
                                            <Circle size={24} />
                                            <div>
                                                <CardTitle>State code</CardTitle>
                                                <CardDescription className='text-[16px] mt-3'>{company?.v_state_code}</CardDescription>
                                            </div>
                                        </div>
                                    </CardHeader>
                                </Card>
                            }
                            {
                                company?.v_country_code &&
                                <Card className='flex-1 flex justify-center items-center'>
                                    <CardHeader>
                                        <div className='flex gap-3'>
                                            <Circle size={24} />
                                            <div>
                                                <CardTitle>Country code</CardTitle>
                                                <CardDescription className='text-[16px] mt-3'>{company?.v_country_code}</CardDescription>
                                            </div>
                                        </div>
                                    </CardHeader>
                                </Card>
                            }
                        </div>
                    </CardContent>
                </Card>
            }
        </div>
    )
}

export default CompanyById