// src/pages/NotFound.tsx
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-[var(--background)] text-[var(--foreground)]">
            <h1 className="text-6xl mb-4">404</h1>
            <p className="text-2xl mb-8">Page Not Found</p>
            <Link to="/" className="text-blue-500 hover:underline">
                Go back
            </Link>
        </div>
    );
};

export default NotFound;
