import { useEffect, useState } from 'react'
import apiInstance from '../services/api';
import { Bar, BarChart, CartesianGrid, LabelList, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../components/ui/card';
import { FlagIcon, List, MapPinIcon, RefreshCw, Search, TrendingUp, Users2 } from 'lucide-react';
import { ChartContainer, ChartTooltip, ChartTooltipContent } from '../components/ui/chart';
import { Skeleton } from '../components/ui/skeleton';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/ui/table';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../components/ui/select';
import { Button } from '../components/ui/button';
import ReactCountryFlag from 'react-country-flag';

const Dashboard = () => {

    const [mostFrequentedVisitors, setMostFrequentedVisitors] = useState<any>(null);
    const [dashboardBoxes, setDashboardBoxes] = useState<any>(null);
    const [visitorsTrackingLast30Days, setVisitorsTrackingLast30Days] = useState<any>(null);
    const [countries, setCountries] = useState<any[]>([]);
    const [country, setCountry] = useState<string>("")
    const [matchingVisitors, setMatchingVisitors] = useState<any[] | null>(null)

    const getMostFrequentedVisitors = async () => {
        try {
            const response = await apiInstance.apiGetMostFrequentedVisitors();
            setMostFrequentedVisitors(response);
        } catch (error) {
            console.log(error);
        }
    }

    const getDashboardBoxes = async () => {
        try {
            const response = await apiInstance.apiGetDashboardBoxes();
            setDashboardBoxes(response);

        } catch (error) {
            console.log(error);
        }
    }

    const getvisitorsTrackingLast30Days = async () => {
        try {
            setCountry("");
            const response = await apiInstance.apiGetvisitorsTrackingLast30Days();
            setVisitorsTrackingLast30Days(response);
        } catch (error) {
            console.log(error);
        }
    }

    const getvisitorsTrackingLast30DaysByCountry = async () => {
        try {
            const response = await apiInstance.apiGetvisitorsTrackingLast30DaysByCountry(country);
            setVisitorsTrackingLast30Days(response);
        } catch (error) {
            console.log(error);
        }
    }

    const getCountries = async () => {
        try {
            const response = await apiInstance.apiGetListOfCountries();
            setCountries(response);

        } catch (error) {
            console.error("Failed to fetch list", error);
        }
    }

    const getMatchingVisitors = async () => {
        try {
            const response = await apiInstance.apiGetMatchingVisitors();
            console.log(response);
            setMatchingVisitors(response);
        } catch (error) {
            console.error("Failed to fetch list", error);
        }
    }

    useEffect(() => {
        getMostFrequentedVisitors();
        getDashboardBoxes();
        getvisitorsTrackingLast30Days();
        getCountries();
        getMatchingVisitors();
    }, [])

    useEffect(() => {
        if (country !== "")
            getvisitorsTrackingLast30DaysByCountry();
    }, [country])

    return (
        <div>
            <div className='flex flex-col gap-3 mb-3 xl:flex-row'>
                <div className='flex-1 grid grid-cols-1 gap-3 w-[100%] xl:w-[50%]'>
                    {
                        dashboardBoxes ?
                            <Card className='flex-[1]'>
                                <CardHeader className='flex flex-row justify-between items-center'>
                                    <CardDescription className='text-[16px]'>Number of visits</CardDescription>
                                    <List size={40} />
                                </CardHeader>
                                <CardContent>
                                    <CardTitle>{dashboardBoxes[0][0].number_of_visits}</CardTitle>
                                </CardContent>
                            </Card>
                            :
                            <Skeleton className="w-[100%] min-h-[147px] rounded-md" />
                    }
                    {
                        dashboardBoxes ?
                            <Card className='flex-1'>
                                <CardHeader className='flex flex-row justify-between items-center'>
                                    <CardDescription className='text-[16px]'>Number of locations</CardDescription>
                                    <MapPinIcon size={40} />
                                </CardHeader>
                                <CardContent className='flex gap-3 flex-wrap'>
                                    {dashboardBoxes[0][0].location_codes.split(",").map((code: string) => {
                                        return <ReactCountryFlag title={code} countryCode={code} svg style={{ width: 35, height: 35 }} />;
                                    })}
                                </CardContent>
                            </Card>
                            :
                            <Skeleton className="w-[100%] min-h-[147px] rounded-md" />
                    }
                    {
                        dashboardBoxes ?
                            <Card className='flex-1'>
                                <CardHeader className='flex flex-row justify-between items-center'>
                                    <CardDescription className='text-[16px]'>Number of tracked visitors</CardDescription>
                                    <Search size={40} />
                                </CardHeader>
                                <CardContent>
                                    <CardTitle>{dashboardBoxes[0][0].number_of_visitors_tracked}</CardTitle>
                                </CardContent>
                            </Card>
                            :
                            <Skeleton className="w-[100%] min-h-[147px] rounded-md" />
                    }
                </div>
                {
                    dashboardBoxes ?
                        <Card className='flex-1 flex flex-col w-[100%] xl:w-[50%]'>
                            <CardHeader>
                                <CardTitle>Industries</CardTitle>
                                <CardDescription>Top 5 industries</CardDescription>
                            </CardHeader>
                            <CardContent>
                                <ChartContainer config={{}}>
                                    <ResponsiveContainer >
                                        <BarChart
                                            barSize={50}
                                            accessibilityLayer
                                            data={dashboardBoxes[2].map((industry: { industry: string, visitor_count: number }) => industry)}
                                            layout="vertical"
                                            margin={{
                                                right: 16,
                                            }}
                                        >
                                            <CartesianGrid horizontal={false} />
                                            <YAxis
                                                dataKey="industry"
                                                type="category"
                                                tickLine={false}
                                                tickMargin={10}
                                                axisLine={false}
                                                tickFormatter={(value) => value.slice(0, 3)}
                                                hide
                                            />
                                            <XAxis dataKey="visitor_count" type="number" hide />
                                            <ChartTooltip
                                                cursor={false}
                                                content={<ChartTooltipContent indicator="line" />}
                                            />
                                            <Bar
                                                dataKey="visitor_count"
                                                name='Visitors'
                                                layout="vertical"
                                                className='fill-[hsl(var(--primary))]'
                                                radius={4}
                                            >
                                                <LabelList
                                                    dataKey="industry"
                                                    position="insideLeft"
                                                    offset={8}
                                                    className="font-semibold fill-black dark:fill-white"
                                                    fontSize={12}
                                                />
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </ChartContainer>
                            </CardContent>
                        </Card>
                        :
                        <Skeleton className="flex-1 min-h-[325px] rounded-md" />
                }
            </div>
            {
                mostFrequentedVisitors ?
                    <div className='flex gap-3 w-full mb-3'>
                        <Card className='flex-1'>
                            <CardHeader>
                                <div className='flex items-center gap-3'>
                                    <Users2 size={30} />
                                    <CardTitle>Most frequented visitors</CardTitle>
                                </div>
                            </CardHeader>
                            <CardDescription className='text-[16px] mx-4'>
                                <div>
                                    <ChartContainer config={{}}>
                                        <LineChart
                                            accessibilityLayer
                                            data={mostFrequentedVisitors}
                                            margin={{
                                                top: 20,
                                                left: 12,
                                                right: 12,
                                            }}
                                        >
                                            <CartesianGrid vertical={false} />
                                            <ChartTooltip
                                                cursor={false}
                                                content={<ChartTooltipContent indicator="line" />}
                                            />
                                            <XAxis
                                                height={100}
                                                angle={75}
                                                dataKey="visitor_name"
                                                axisLine={false}
                                                tickMargin={38}
                                                tickFormatter={(value) => value.slice(0, 10) + (value.length > 10 ? "..." : "")}
                                            />
                                            <Line
                                                dataKey="number_of_visits"
                                                name='Visits'
                                                type="natural"
                                                stroke="hsl(var(--primary))"
                                                strokeWidth={2}
                                                dot={{
                                                    fill: "var(--primary)",
                                                }}
                                                activeDot={{
                                                    r: 6,
                                                }}
                                            >
                                                <LabelList
                                                    position="top"
                                                    offset={12}
                                                    className="fill-foreground"
                                                    fontSize={12}
                                                />
                                            </Line>
                                        </LineChart>
                                    </ChartContainer>
                                </div>
                            </CardDescription>
                        </Card>
                    </div>
                    :
                    <Skeleton className="flex-1 min-h-[280px] rounded-md mb-3" />
            }
            <Card className='flex-1 mb-3'>
                <CardHeader className='flex flex-row justify-between'>
                    <div>
                        <CardTitle>Total new visitors for last 30 days</CardTitle>
                        <CardDescription>January - March</CardDescription>
                    </div>
                    <div className='flex items-center gap-2'>
                        <div>
                            <Button type='button' className='flex items-center border-[rgb(226 232 240)] border bg-transparent gap-[10px] hover:bg-unset' onClick={() => getvisitorsTrackingLast30Days()}><RefreshCw stroke='hsl(var(--foreground))' /> </Button>
                        </div>
                        <Select value={country} onValueChange={(value) => {
                            setCountry(value)
                        }}>
                            <SelectTrigger className="w-[180px]">
                                <SelectValue placeholder="Country" />
                            </SelectTrigger>
                            <SelectContent>
                                {
                                    countries.map(({ country_code, country, uuid }, i) => {
                                        return (
                                            <SelectItem key={i} value={uuid}>{country}</SelectItem>
                                        )
                                    })
                                }
                            </SelectContent>
                        </Select>
                    </div>
                </CardHeader>
                <CardContent>
                    <ChartContainer config={{}}>
                        <BarChart
                            accessibilityLayer
                            data={visitorsTrackingLast30Days}
                            margin={{
                                top: 20,
                            }}
                        >
                            <CartesianGrid vertical={false} />
                            <XAxis
                                height={85}
                                angle={75}
                                dataKey="visitors_first_day"
                                tickLine={false}
                                axisLine={false}
                                tickMargin={38}
                            />
                            <ChartTooltip
                                cursor={false}
                                content={<ChartTooltipContent hideLabel />}
                            />
                            <Bar dataKey="visitors_count" name="Visitors" fill="hsl(var(--primary))" radius={8}>
                                <LabelList
                                    position="top"
                                    offset={12}
                                    className="fill-foreground"
                                    fontSize={12}
                                />
                            </Bar>
                        </BarChart>
                    </ChartContainer>
                </CardContent>
            </Card>
            {
                matchingVisitors &&
                <Card className='mb-3'>
                    <CardHeader className="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
                        <div className="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
                            <CardTitle>Total matches for tracked visitors</CardTitle>
                            <CardDescription>
                                Showing total matching visitors for the last 30 days
                            </CardDescription>
                        </div>
                        <div className="flex">
                            <div className="flex flex-1 flex-col justify-center gap-1 px-6 py-4 text-left border-l">
                                <span className="text-s text-muted-foreground">
                                    Total visitors
                                </span>
                                <span className="text-lg font-bold leading-none sm:text-3xl">
                                    {matchingVisitors.reduce((acc: any, value: any) => acc + value.visitors_count, 0)}
                                </span>
                            </div>
                        </div>
                    </CardHeader>
                    <CardContent className="px-2 sm:p-6">
                        <ChartContainer
                            config={{}}
                            className="aspect-auto h-[250px] w-full"
                        >
                            <LineChart
                                accessibilityLayer
                                data={matchingVisitors}
                                margin={{
                                    left: 12,
                                    right: 12,
                                }}
                            >
                                <CartesianGrid vertical={false} />
                                <XAxis
                                    dataKey="last_visit_date"
                                    tickLine={false}
                                    axisLine={false}
                                    tickMargin={8}
                                    minTickGap={32}
                                    tickFormatter={(value) => {
                                        const date = new Date(value)
                                        return date.toLocaleDateString("en-US", {
                                            month: "short",
                                            day: "numeric",
                                        })
                                    }}
                                />
                                <ChartTooltip
                                    content={
                                        <ChartTooltipContent
                                            className="w-[150px]"
                                            nameKey="views"
                                            labelFormatter={(value) => {
                                                return new Date(value).toLocaleDateString("en-US", {
                                                    month: "short",
                                                    day: "numeric",
                                                    year: "numeric",
                                                })
                                            }}
                                        />
                                    }
                                />
                                <Line
                                    dataKey="visitors_count"
                                    name='Visitors'
                                    stroke='hsl(var(--primary))'
                                    strokeWidth={2}
                                    dot={false}
                                />
                            </LineChart>
                        </ChartContainer>
                    </CardContent>
                </Card>
            }
            {
                dashboardBoxes ?
                    <Card
                        className="xl:col-span-2 flex-1" x-chunk="dashboard-01-chunk-4"
                    >
                        <CardHeader className="flex flex-row items-center">
                            <div className="grid gap-2">
                                <CardTitle>Countries</CardTitle>
                                <CardDescription>
                                    Top 10 countries
                                </CardDescription>
                            </div>
                        </CardHeader>
                        <CardContent>
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead>Country</TableHead>
                                        <TableHead className="text-right">Visitors</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {
                                        dashboardBoxes[1].map((country: {
                                            country: string,
                                            "count(v.id)": number
                                        }) => {
                                            return (
                                                <TableRow>
                                                    <TableCell className='flex items-center gap-2'>
                                                        <FlagIcon size={18} className='' />
                                                        <div className="font-medium">{country.country}</div>
                                                    </TableCell>
                                                    <TableCell className="text-right">{country['count(v.id)']}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                    :
                    <Skeleton className="flex-1 min-h-[450px] rounded-md" />
            }
        </div>
    )
}

export default Dashboard