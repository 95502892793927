// src/components/ThemeToggle.tsx
import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { Switch } from './ui/switch';

const ThemeToggle: React.FC = () => {
    const { theme, toggleTheme } = useTheme();
    return (
        <div className='p-2 flex flex-row align-center justify-center content-center'>
            <span className="mr-2">Theme</span>
            <Switch onClick={toggleTheme} checked={theme === 'dark'} />
        </div>
    );
};

export default ThemeToggle;
