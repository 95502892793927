import dayjs from 'dayjs';
import apiInstance from '../../services/api';
import React, { useEffect, useState } from 'react'
import { Button } from '../ui/button';
import { Link } from 'react-router-dom';

const Notifications = () => {

    const [notifications, setNotifications] = useState<any>([]);
    const [notificationsResponseCount, setNotificationsResponseCount] = useState<any>([]);
    const [offset, setOffset] = useState<number>(0);

    const getAllNotifications = async (offset_val: number = offset) => {
        try {
            const response = await apiInstance.apiGetAllNotifications(10, offset_val);
            setNotificationsResponseCount(response.length);
            setNotifications([...notifications, ...response]);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getAllNotifications();
    }, [offset])

    return (
        <div>
            <div className='max-h-[400px] overflow-auto'>
                {
                    notifications.map((n: any, i: number) => {
                        var date = dayjs.unix(n.created_at_unix).format('ddd MMM DD YYYY HH:mm:ss');

                        return (
                            <Link to={n.status === 0 ? "/person/17" : "/user/" + n.uuid} key={n.id} className={`${n.seen === 0 ? "dark:bg-white dark:bg-opacity-15 bg-black bg-opacity-10" : ""} ${i < notifications.length - 1 ? "border-b" : ""} block p-3`}>
                                <h1 className='text-[18px]'>{n.name}</h1>
                                <p className='text-[14px]'>{n.description}</p>
                                <p className='text-[14px] text-gray-400'>{date}</p>
                            </Link>
                        )
                    })
                }
            </div>
            <Button disabled={notificationsResponseCount !== 10} className='flex items-center gap-[10px] mx-auto my-3 bg-[hsl(var(--primary))] text-white' onClick={() => {
                if (notificationsResponseCount === 10) {
                    setOffset(offset + 10)
                }
            }}>Load more...</Button>
        </div>
    )
}

export default Notifications;