import { useEffect, useState } from "react"
import { Button } from "../components/ui/button"
import { Card, CardContent, CardTitle } from "../components/ui/card"
import { Skeleton } from "../components/ui/skeleton"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table"
import { Link, NavLink } from "react-router-dom"
import apiInstance from "../services/api"
import dayjs from "dayjs"

const Matching = () => {
  const [companies, setCompanies] = useState<any[]>([]);
  const [people, setPeople] = useState<any[]>([]);

  const getCompanies = async () => {
    try {
      const response = await apiInstance.getMatchingCompaniesWithTrackingVisitors();
      console.log(response);

      setCompanies(response); // Adjust according to the response structure
    } catch (error) {
      console.error("Failed to fetch list", error);
    }
  }

  const getPeople = async () => {
    try {
      const response = await apiInstance.getMatchedPeopleWithTrackVisitors();
      console.log(response);

      setPeople(response); // Adjust according to the response structure
    } catch (error) {
      console.error("Failed to fetch list", error);
    }
  }

  useEffect(() => {
    getPeople();
    getCompanies();
  }, [])

  return (
    <div className="">
      <div className='w-full block mb-[100px]'>
        <h1 className="text-4xl text-center hidden xl:block mb-10">Matching companies</h1>
        <div className='max-w-full'>
          {
            companies !== null ?
              companies.length ?
                <Table className='min-w-[1400px]'>
                  <TableHeader>
                    <TableRow>
                      <TableHead className='whitespace-nowrap'>Name</TableHead>
                      <TableHead className='whitespace-nowrap'>Website url</TableHead>
                      <TableHead className='whitespace-nowrap'>Facebook url</TableHead>
                      <TableHead className='whitespace-nowrap'>Linkedin url</TableHead>
                      <TableHead className='whitespace-nowrap'>Twitter handle</TableHead>
                      <TableHead className='whitespace-nowrap'>Employee count</TableHead>
                      <TableHead className='whitespace-nowrap'>First visit date</TableHead>
                      <TableHead className='whitespace-nowrap'>Last visit date</TableHead>
                      <TableHead className='whitespace-nowrap'>Country</TableHead>
                      <TableHead className='whitespace-nowrap'>City</TableHead>
                      <TableHead className='whitespace-nowrap'>Region</TableHead>
                      <TableHead className='whitespace-nowrap'>Visits</TableHead>
                      <TableHead className='whitespace-nowrap'></TableHead>
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {companies.map((item: any, i: number) => {
                      var date = dayjs.unix(item.created_at_unix).format('ddd MMM DD YYYY HH:mm:ss');
                      return (
                        <TableRow key={i}>
                          <TableCell className="font-medium">
                            <div className='flex items-center gap-2'>
                              <div className={`bg-[rgba(0,0,0,.4)] text-white text-center flex items-center justify-center rounded-md overflow-hidden min-w-[50px] w-[50px] h-[50px]`}>{item.logo_url ? <img className='object-contain h-full w-full' src={item.logo_url} alt='logo' /> : "N/A"}</div>
                              {item.name}
                            </div>
                          </TableCell>
                          <TableCell>{item.website_url ? <Link target='_blank' to={item.website_url} className='text-blue-500'>{item.website_url || "N/A"}</Link> : "N/A"}</TableCell>
                          <TableCell>{item.facebook_url ? <Link target='_blank' to={item.facebook_url} className='text-blue-500'></Link> : "N/A"}</TableCell>
                          <TableCell>{item.linkedin_url ? <Link target='_blank' to={item.linkedin_url} className='text-blue-500'>{item.linkedin_url}</Link> : "N/A"}</TableCell>
                          <TableCell>{item.twitter_handle}</TableCell>
                          <TableCell>{item.employee_count}</TableCell>
                          <TableCell className='w-[160px]'>{item.first_visit_date}</TableCell>
                          <TableCell className='w-[160px]'>{item.last_visit_date}</TableCell>
                          <TableCell className='min-w-[150px]'>{item.country} ({item.country_code})</TableCell>
                          <TableCell>{item.city}</TableCell>
                          <TableCell>{item.region}</TableCell>
                          <TableCell>{item.visits}</TableCell>
                          <TableCell className='sticky right-[-1px] px-4 bg-white dark:bg-slate-950'>
                            <NavLink to={`/companies/${item.uuid}`} type='button'>
                              <Button type='button' className='flex items-center gap-[10px]'>View</Button>
                            </NavLink>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
                :
                <Card className='flex-1'>
                  <CardContent className='p-0'>
                    <CardTitle className='p-4 text-center'>No data available!</CardTitle>
                  </CardContent>
                </Card>
              :
              <Skeleton className="w-[100%] min-h-[650px] rounded-md" />
          }
        </div>
      </div>
      <div className='w-full block'>
        <h1 className="text-4xl text-center hidden xl:block mb-10">Matching people</h1>
        <div className='max-w-full'>
          {
            people !== null ?
              people.length ?
                <Table className='min-w-[1400px]'>
                  <TableHeader>
                    <TableRow>
                      <TableHead className='whitespace-nowrap'>Name</TableHead>
                      <TableHead className='whitespace-nowrap'>Email</TableHead>
                      <TableHead className='whitespace-nowrap'>First name</TableHead>
                      <TableHead className='whitespace-nowrap'>First visited at</TableHead>
                      <TableHead className='whitespace-nowrap'>Last name</TableHead>
                      <TableHead className='whitespace-nowrap'>Last visited at</TableHead>
                      <TableHead className='whitespace-nowrap'>Page views</TableHead>
                      <TableHead className='whitespace-nowrap'>Quality</TableHead>
                      <TableHead className='whitespace-nowrap'>State code</TableHead>
                      <TableHead className='whitespace-nowrap'>Visits</TableHead>
                      <TableHead className='whitespace-nowrap'></TableHead>
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {people.map((item: any, i: number) => (
                      <TableRow key={i}>
                        <TableCell className="font-medium">{item.name ? item.name : "N/A"}</TableCell>
                        <TableCell>{item.email ? item.email : "N/A"}</TableCell>
                        <TableCell>{item.first_name ? item.first_name : "N/A"}</TableCell>
                        <TableCell>{item.first_visited_at ? item.first_visited_at : "N/A"}</TableCell>
                        <TableCell>{item.last_name ? item.last_name : "N/A"}</TableCell>
                        <TableCell>{item.last_visited_at ? item.last_visited_at : "N/A"}</TableCell>
                        <TableCell>{item.pageviews ? item.pageviews : "N/A"}</TableCell>
                        <TableCell>{item.quality ? item.quality : "N/A"}</TableCell>
                        <TableCell>{item.state_code ? item.state_code : "N/A"}</TableCell>
                        <TableCell>{item.visits ? item.visits : "N/A"}</TableCell>
                        <TableCell className='sticky right-[-1px] px-4 bg-white dark:bg-slate-950'>
                          <NavLink to={`/person/${item.id}`} type='button'>
                            <Button type='button' className='flex items-center gap-[10px]'>View</Button>
                          </NavLink>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                :
                <Card className='flex-1'>
                  <CardContent className='p-0'>
                    <CardTitle className='p-4 text-center'>No data available!</CardTitle>
                  </CardContent>
                </Card>
              :
              <Skeleton className="w-[100%] min-h-[650px] rounded-md" />
          }
        </div>
      </div>
    </div>
  )
}

export default Matching