import { useEffect, useState } from 'react'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../components/ui/card'
import apiInstance from '../services/api'
import { Link, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { Building, Calendar, Circle, Facebook, Flag, Globe, Linkedin, Mail, Map, PinIcon, Square, User, User2, X } from 'lucide-react'
import { Button } from '../components/ui/button'

const NotificationUser = () => {

    let { id } = useParams();

    const [person, setPerson] = useState<any>(null);

    const getUserDetails = async () => {
        try {
            if (id !== undefined) {
                const response = await apiInstance.apiGetPeopleById(parseInt(id));
                setPerson(response);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getUserDetails();
    }, [id])

    return (
        person ?
            <div>
                <h1 className='text-[36px] font-bold mb-4 flex items-center gap-3'>{person.name || "N/A"} </h1>
                <div className='flex gap-3 w-full flex-col md:flex-row'>
                    <Card className='flex-1'>
                        <CardHeader>
                            <PinIcon size={40} />
                            <CardDescription className='text-[16px]'>Page views</CardDescription>
                            <CardTitle>{person?.pageviews}</CardTitle>
                        </CardHeader>
                    </Card>
                    <Card className='flex-1'>
                        <CardHeader>
                            <Calendar size={40} />
                            <CardDescription className='text-[16px]'>First visit date</CardDescription>
                            <CardTitle>{person?.first_visited_at}</CardTitle>
                        </CardHeader>
                    </Card>
                    <Card className='flex-1'>
                        <CardHeader>
                            <Calendar size={40} />
                            <CardDescription className='text-[16px]'>Last visit date</CardDescription>
                            <CardTitle>{person?.last_visited_at}</CardTitle>
                        </CardHeader>
                    </Card>
                </div>
                <hr className='my-10' />
                {
                    (person?.country || person?.city || person?.region || person?.state_code || person?.country_code || person?.email || person?.visitor_name || person?.quality) &&
                    <Card>
                        <CardHeader>
                            <CardTitle>Other details</CardTitle>
                        </CardHeader>
                        <CardContent className='flex gap-10'>
                            {/* <img src='/user-details-svg.svg' className='opacity-50 w-full max-w-[400px]' /> */}
                            <div className='w-full gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                                {
                                    person?.country &&
                                    <Card className='flex-1 flex justify-center items-center min-h-[175px]'>
                                        <CardHeader>
                                            <div className='flex gap-3'>
                                                <Map size={24} />
                                                <div>
                                                    <CardTitle>Country</CardTitle>
                                                    <CardDescription className='text-[16px] mt-3'>{person?.country}</CardDescription>
                                                </div>
                                            </div>
                                        </CardHeader>
                                    </Card>
                                }
                                {
                                    person?.city &&
                                    <Card className='flex-1 flex justify-center items-center min-h-[175px]'>
                                        <CardHeader>
                                            <div className='flex gap-3'>
                                                <Building size={24} />
                                                <div>
                                                    <CardTitle>City</CardTitle>
                                                    <CardDescription className='text-[16px] mt-3'>{person?.city}</CardDescription>
                                                </div>
                                            </div>
                                        </CardHeader>
                                    </Card>
                                }
                                {
                                    person?.region &&
                                    <Card className='flex-1 flex justify-center items-center min-h-[175px]'>
                                        <CardHeader>
                                            <div className='flex gap-3'>
                                                <Flag size={24} />
                                                <div>
                                                    <CardTitle>Region</CardTitle>
                                                    <CardDescription className='text-[16px] mt-3'>{person?.region}</CardDescription>
                                                </div>
                                            </div>
                                        </CardHeader>
                                    </Card>
                                }
                                {
                                    person?.state_code &&
                                    <Card className='flex-1 flex justify-center items-center min-h-[175px]'>
                                        <CardHeader>
                                            <div className='flex gap-3'>
                                                <Circle size={24} />
                                                <div>
                                                    <CardTitle>State code</CardTitle>
                                                    <CardDescription className='text-[16px] mt-3'>{person?.state_code}</CardDescription>
                                                </div>
                                            </div>
                                        </CardHeader>
                                    </Card>
                                }
                                {
                                    person?.country_code &&
                                    <Card className='flex-1 flex justify-center items-center min-h-[175px]'>
                                        <CardHeader>
                                            <div className='flex gap-3'>
                                                <Circle size={24} />
                                                <div>
                                                    <CardTitle>Country code</CardTitle>
                                                    <CardDescription className='text-[16px] mt-3'>{person?.country_code}</CardDescription>
                                                </div>
                                            </div>
                                        </CardHeader>
                                    </Card>
                                }
                                {
                                    person?.email &&
                                    <Card className='flex-1 flex justify-center items-center min-h-[175px]'>
                                        <CardHeader>
                                            <div className='flex gap-3'>
                                                <Mail size={24} />
                                                <div>
                                                    <CardTitle>Email</CardTitle>
                                                    <CardDescription className='text-[16px] mt-3'>{person?.email}</CardDescription>
                                                </div>
                                            </div>
                                        </CardHeader>
                                    </Card>
                                }
                                {
                                    person?.visitor_name &&
                                    <Card className='flex-1 flex justify-center items-center min-h-[175px]'>
                                        <CardHeader>
                                            <div className='flex gap-3'>
                                                <User size={24} />
                                                <div>
                                                    <CardTitle>Visitor name</CardTitle>
                                                    <CardDescription className='text-[16px] mt-3'>{person?.visitor_name}</CardDescription>
                                                </div>
                                            </div>
                                        </CardHeader>
                                    </Card>
                                }
                                {
                                    person?.quality &&
                                    <Card className='flex-1 flex justify-center items-center min-h-[175px]'>
                                        <CardHeader>
                                            <div className='flex gap-3'>
                                                <Square size={24} />
                                                <div>
                                                    <CardTitle>Quality</CardTitle>
                                                    <CardDescription className='text-[16px] mt-3'>{person?.quality}</CardDescription>
                                                </div>
                                            </div>
                                        </CardHeader>
                                    </Card>
                                }
                            </div>
                        </CardContent>
                    </Card>
                }
            </div>
            :
            null
    )
}

export default NotificationUser