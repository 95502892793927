// src/pages/tracked-visitors.tsx
import React, { useEffect, useState } from 'react';
import apiInstance from '../services/api';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../components/ui/table";
import { Link, NavLink } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { CalendarIcon, Filter, RefreshCw } from 'lucide-react';
import dayjs from 'dayjs';
import { DateRange } from 'react-day-picker';
import { format } from 'date-fns';
import { Popover, PopoverContent, PopoverTrigger } from '../components/ui/popover';
import { Calendar } from '../components/ui/calendar';
import { Pagination, PaginationContent, PaginationEllipsis, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious } from '../components/ui/pagination';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../components/ui/select';
import { Skeleton } from '../components/ui/skeleton';
import { Card, CardContent, CardTitle } from '../components/ui/card';

const People: React.FC = () => {

    const [list, setList] = useState<any[]>([]);
    const [date, setDate] = useState<DateRange | undefined>({
        from: undefined,
        to: undefined,
    })

    const fetchList = async () => {
        try {
            const response = await apiInstance.apiGetPeople();
            console.log(response);

            setList(response); // Adjust according to the response structure
        } catch (error) {
            console.error("Failed to fetch list", error);
        }
    };

    useEffect(() => {
        fetchList();
    }, []);

    return (
        <div className='w-full block'>
            <h1 className="text-4xl text-center hidden xl:block mb-10">People</h1>
            <div className='max-w-full'>
                {
                    list !== null ?
                        list.length ?
                            <Table className='min-w-[1400px]'>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead className='whitespace-nowrap'>Name</TableHead>
                                        <TableHead className='whitespace-nowrap'>City</TableHead>
                                        <TableHead className='whitespace-nowrap'>Country</TableHead>
                                        <TableHead className='whitespace-nowrap'>Email</TableHead>
                                        <TableHead className='whitespace-nowrap'>First name</TableHead>
                                        <TableHead className='whitespace-nowrap'>First visited at</TableHead>
                                        <TableHead className='whitespace-nowrap'>Last name</TableHead>
                                        <TableHead className='whitespace-nowrap'>Last visited at</TableHead>
                                        <TableHead className='whitespace-nowrap'>Lookup strategy</TableHead>
                                        <TableHead className='whitespace-nowrap'>Pageviews</TableHead>
                                        <TableHead className='whitespace-nowrap'>Quality</TableHead>
                                        <TableHead className='whitespace-nowrap'>Region</TableHead>
                                        <TableHead className='whitespace-nowrap'>State code</TableHead>
                                        <TableHead className='whitespace-nowrap'>Visitor name</TableHead>
                                        <TableHead className='whitespace-nowrap'>visits</TableHead>
                                        <TableHead className='whitespace-nowrap'></TableHead>
                                    </TableRow>
                                </TableHeader>

                                <TableBody>
                                    {list.map((item: any, i: number) => (
                                        <TableRow key={i}>
                                            <TableCell className="font-medium">{item.name ? item.name : "N/A"}</TableCell>
                                            <TableCell>{item.city ? item.city : "N/A"}</TableCell>
                                            <TableCell>{item.country ? item.country : "N/A"}({item.country_code ? item.country_code : "N/A"})</TableCell>
                                            <TableCell>{item.email ? item.email : "N/A"}</TableCell>
                                            <TableCell>{item.first_name ? item.first_name : "N/A"}</TableCell>
                                            <TableCell>{item.first_visited_at ? item.first_visited_at : "N/A"}</TableCell>
                                            <TableCell>{item.last_name ? item.last_name : "N/A"}</TableCell>
                                            <TableCell>{item.last_visited_at ? item.last_visited_at : "N/A"}</TableCell>
                                            <TableCell>{item.lookup_strategy ? item.lookup_strategy : "N/A"}</TableCell>
                                            <TableCell>{item.pageviews ? item.pageviews : "N/A"}</TableCell>
                                            <TableCell>{item.quality ? item.quality : "N/A"}</TableCell>
                                            <TableCell>{item.region ? item.region : "N/A"}</TableCell>
                                            <TableCell>{item.state_code ? item.state_code : "N/A"}</TableCell>
                                            <TableCell>{item.visitor_name ? item.visitor_name : "N/A"}</TableCell>
                                            <TableCell>{item.visits ? item.visits : "N/A"}</TableCell>
                                            <TableCell className='sticky right-[-1px] px-4 bg-white dark:bg-slate-950'>
                                                <NavLink to={`/person/${item.id}`} type='button'>
                                                    <Button type='button' className='flex items-center gap-[10px]'>View</Button>
                                                </NavLink>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            :
                            <Card className='flex-1'>
                                <CardContent className='p-0'>
                                    <CardTitle className='p-4 text-center'>No data available!</CardTitle>
                                </CardContent>
                            </Card>
                        :
                        <Skeleton className="w-[100%] min-h-[650px] rounded-md" />
                }
            </div>
        </div >
    );
};

export default People;
