// src/pages/LoginPage.tsx
import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import apiInstance from '../services/api';
import { useToast } from "../components/ui/use-toast";
import { Input } from "../components/ui/input";


interface IFormInput {
    email: string;
    password: string;
}

const schema = yup.object().shape({
    email: yup.string().email('Invalid email format').required('Email is required'),
    password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});

const LoginPage: React.FC = () => {
    const { toast } = useToast()

    const { register, handleSubmit, formState: { errors } } = useForm<IFormInput>({
        resolver: yupResolver(schema),
    });
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const onSubmit: SubmitHandler<IFormInput> = async data => {
        try {
            const { data: { message, token } } = await apiInstance.login(data.email, data.password);

            if (token) {
                localStorage.setItem('token', token);
                toast({
                    title: "Here you go!",
                    description: "You have succesfully logged in.",
                })
                setTimeout(() => {
                    navigate('/dashboard');
                }, 1000)
            }
        } catch (error) {
            setErrorMessage('Something went wrong. Please try again.');
        }
    };

    return (
        <div className="max-w-[420px] w-full mx-auto p-4 h-screen flex flex-col items-center mt-20">
            <h1 className="text-2xl mb-4">Login</h1>
            <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4">
                    <label htmlFor="email" className="block mb-2">Email</label>
                    <Input
                        type="text"
                        id="email"
                        {...register('email')}
                        className={`block w-full p-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded mb-2`}
                    />
                    {errors.email && <p className="text-red-500">{errors.email.message}</p>}
                </div>
                <div className="mb-4">
                    <label htmlFor="password" className="block mb-2">Password</label>
                    <Input
                        type="password"
                        id="password"
                        {...register('password')}
                        className={`block w-full p-2 border ${errors.password ? 'border-red-500' : 'border-gray-300'} rounded mb-2`}
                    />
                    {errors.password && <p className="text-red-500">{errors.password.message}</p>}
                </div>
                <button
                    type="submit"
                    className="block w-full p-2 bg-gray-800 text-white rounded"
                >
                    Login
                </button>
                {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
            </form>
        </div>
    );
};

export default LoginPage;
